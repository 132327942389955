module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://983af691d86141cca0db4f9563c87bb6@o555157.ingest.sentry.io/5684654","sampleRate":0.7},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s – Richardson Shop","language":"ja-JP","description":"Richardson is an American clothing retailer and independent magazine publication exploring art, sex, and culture founded by Andrew Richardson in 1998. © 2021, Richardson Shop @richardsonworld","dangerouslySetAllPagesToNoIndex":false,"dangerouslySetAllPagesToNoFollow":false,"openGraph":{"type":"website","locale":"jaJP","url":"https://jp.richardsonshop.com","site_name":"Richardson Shop","description":"Richardson is an American clothing retailer and independent magazine publication exploring art, sex, and culture founded by Andrew Richardson in 1998. © 2021, Richardson Shop @richardsonworld","images":[{"url":"https://jp.richardsonshop.com/logo.jpg","width":1200,"height":1200,"alt":"Logo"}]},"twitter":{"cardType":"summary_large_image"},"metaTags":[{"name":"keywords","content":["Richardson","Shop","Streetware","New York","Los Angeles","Tokyo"]},{"name":"facebook-domain-verification","content":"ooqdhuhiek3idg32tlq3tf371p5r4l"}],"languageAlternates":[{"hrefLang":"x-default","href":"https://richardsonshop.com"},{"hrefLang":"en","href":"https://us.richardsonshop.com"},{"hrefLang":"ja","href":"https://jp.richardsonshop.com"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-PTHH7W7"},"environments":["production"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Richardson Shop","description":"Richardson is an American clothing retailer and independent magazine publication exploring art, sex, and culture founded by Andrew Richardson in 1998. © 2021, Richardson Shop @richardsonworld","start_url":"/","lang":"ja","icon":"src/images/icon.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"262778f8c804e784cc33de05d0077e55"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
